<template>
    <div class="search-wrapper">
        <div class="header">
            <div class="title">Search</div>
        </div>
        <div class="searchbar-wrap">
            <input class="searchbar" type="text" placeholder="Search query..." v-model="query" @keyup="filterShanties()">
        </div>
        <hr class="sleek-line">
        <div class="result-wrap">
            <div class="empty" v-if="query == ''">
                <Search :size='40'/>
                <div class="msg">
                    Enter a search query to begin
                </div>
            </div>
            <div class="no-results" v-else-if="!matching.length">
                <MusicNoteOff :size='40'/>
                <div class="msg">
                    No matching results
                </div>
            </div>
            <div class="results" v-else>
                <div class="result-card" v-for="match in matching" :key="match.file">
                    <router-link class="result-link" :to="'/shanty/'+match.file"><div class="card-name">{{match.name}}</div></router-link>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import shantyList from '@/assets/shanties/available-shanties.json'
import MusicNoteOff from 'vue-material-design-icons/MusicNoteOff.vue';
import Search from 'vue-material-design-icons/Magnify.vue';

export default {
    components: {
        MusicNoteOff,
        Search
    },
    data: function () {
        return {
            query: "",
            matching: [],
            shanties: shantyList.shanties
        }
    },
    methods: {
        filterShanties() {
            this.matching = this.shanties.filter(shanty => shanty.name.toLowerCase().includes(this.query.toLowerCase()));

        }
    }
}
</script>

<style lang="scss" scoped>
.search-wrapper {
    padding: 0 1.5rem;
    .header {
        padding: 1.5rem 0 0 0;
        .title {
            font-size: 1.5rem;
            font-weight: 300;
        }
    }
    .searchbar-wrap {
        display: flex;
        .searchbar {
            flex-grow: 1;
            margin: 1rem 0;
            font-size: 1rem;
            padding: .5rem;
            background: none;
            border: none;
            outline: none;
            color: #00f6ff;
            border: 1px solid #00f6ff;
            border-radius: 5px;
        }
    }
    .sleek-line {
        border-top: 1px solid #370087;
        border-bottom: none;
    }
    .result-wrap {
        .no-results,
        .empty {
            padding: 3rem 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #00f6ff;
            .msg {
                padding: 1rem 0;
            }
        }
        .results {
            .result-card {
                background: #25004b;
                border: 1px solid #00f6ff;
                padding: 1rem;
                margin: 1rem 0;
                border-radius: 5px;
                .result-link {
                    color: inherit;
                    text-decoration: none;
                }
                .card-name {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
</style>